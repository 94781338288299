export const uploadModalObject = {
  "ai_use_info": {},
  "created": "",
  "file_gen_specs": [],
  "icon_name_in_s3": null,
  "id": "",
  "is_approved": false,
  "is_published": false,
  "likes": 0,
  "model_name_in_s3": null,
  "modified": "",
  "name": "",
  "price": null,
  "rigging_info": {},
  "scene_org_info": {},
  "uploaded_file_info": {
    "field_icon_view": [],
    "modelInfo": {
      "aIUsedInfo": {
        "aiUsedOption": "",
        "dataFiles": [],
        "description": "",
        "isAiUsed": false,
        "name": ""
      },
      "ageInfo": {
        "months": "",
        "years": ""
      },
      "animationInfo": {
        "animations": [],
        "isAnimated": false
      },
      "availability": "",
      "collaborators": [],
      "generalSpec": {
        "edges": "",
        "faces": "",
        "height": "",
        "isOrganized": false,
        "length": "",
        "pbr": false,
        "polygons": "",
        "quad": "",
        "triangles": "",
        "unit": "",
        "vertices": "",
        "width": "",
        "worldScale": ""
      },
      "hardwareRequirementDescription": "",
      "isIntegrationWithPlatform": "",
      "isViewParty": "",
      "licenseInfo": [],
      "modelName": "",
      "price": null,
      "productPreview": [],
      "riggingInfo": {
        "dataFiles": [],
        "description": "",
        "isRigging": false
      },
      "sceneDescription": "",
      "sceneInfo": {
        "UvMapValue": "",
        "dataFiles": [],
        "description": "",
        "isUvMapping": false,
        "materials": [],
        "textures": []
      },
      "scientificCollaboration": "",
      "scientificDataAndCitation": {
        "isVisible": false,
        "scientificData": []
      },
      "scientificDataType": [],
      "scientificName": ""
    },
    "models": [],
    "scientificInfo": {
      "isPublished": false,
      "methodologyDescription": "",
      "primaryLayer": "",
      "primaryValue": "",
      "publishedInfo": [],
      "referenceDatabases": [],
      "secondaryLayer": [],
      "secondaryValue": [],
      "vocabulary": []
    }
  },
  "user_id": "",
  "view_count": 0
}
