import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo, handleChangeDialogState, submitProfile, updateUserInfo } from '../../actions';
import LogInDialog from '../Login';
import { useNavigate } from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';
import SignUpDialog from '../SignUp';
import OTPDialog from '../OtpDialog';
import PersonalInformation from "../ProfileInformation";


function AllDialogs() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const state = useSelector((state) => state.bios);

    const [snackbarState, setSnackbarState] = React.useState({
        open: false,
        message: '',
        severity: 'success',
        vertical: 'top',
        horizontal: 'center',
    });

    const userProfile = state.user && state.user?.account_info || {};
    console.log("userProfile userProfile", userProfile)

    const snackbarComponent = () => {
        const { vertical, horizontal } = snackbarState;
        return <Snackbar open={snackbarState.open} autoHideDuration={5000} onClose={handleCloseSnackbar}>
            <Alert anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal} onClose={handleCloseSnackbar} severity={snackbarState.severity} sx={{ width: '100%' }}>
                {snackbarState.message}
            </Alert>
        </Snackbar>
    };

    const handleCloseSnackbar = () => {
        setSnackbarState({ open: false, message: '', severity: '' })
    };

    const handleCloseLogin = () => {
        dispatch(handleChangeDialogState({
            loginDialog: false
        }));
    };

    const handleSubmitLogin = (data) => {
        if (!data) {
            // if data is null its means user has not verified its email address
            dispatch(handleChangeDialogState({
                otpDialog: true
            }));
        }
        else {
            handleCloseLogin();
            setSnackbarState({
                open: true,
                message: 'Signed in successfully',
                severity: 'success'
            });
            navigate('/myaccount');
        }
    };

    const handleClickSignUpOpt = () => {
        dispatch(handleChangeDialogState({
            signUpDialog: true,
            loginDialog: false
        }));
    };

    const handleCloseOtp = () => {
        dispatch(handleChangeDialogState({
            otpDialog: false
        }));
    };

    const handleCloseSignUp = () => {
        dispatch(handleChangeDialogState({
            signUpDialog: false
        }));
    };

    const handleClickAlreadyLogin = () => {
        dispatch(handleChangeDialogState({
            signUpDialog: false,
            loginDialog: true
        }));
    };

    const handleSubmitSignUp = () => {
        dispatch(handleChangeDialogState({
            signUpDialog: false,
            otpDialog: true
        }));
        setSnackbarState({
            open: true,
            message: "Sign-up successful! We've sent a verification code to your email address. Please check your inbox.",
            severity: 'success'
        });
    };

    const handleOpenLogin = () => {
        dispatch(handleChangeDialogState({
            otpDialog: false,
            loginDialog: true
        }));
    };

    const handleSubmitOtp = () => {
        handleOpenLogin();
        setSnackbarState({
            open: true,
            message: 'Your OTP has been verified successfully.',
            severity: 'success'
        });
    };

    const handleCloseInfo = () => {
        dispatch(handleChangeDialogState({
            profileDialog: false
        }));
    };

    const handleSubmitInfo = (data) => {
        // const data = { ..._data, ...userProfile };
        let mainKeys = [
            "company",
            "first_name",
            "id",
            "last_name",
            "position",
            "user_type",
            "username",
            "email"
        ];

        let accountInfo = {};
        for (let key in data) {
            if (!mainKeys.includes(key)) {
                // if (data[key]) {
                accountInfo[key] = data[key];
                // }
            }
            delete data[key];  // Remove the key from the original object
        }
        data.account_info = accountInfo;
        dispatch(submitProfile(data, (succ) => {
            setSnackbarState({
                open: true,
                message: "Profile Information Update Successfully!",
                severity: 'success'
            });
            getUserInfo().then((userInfoResponse) => {
                dispatch(updateUserInfo(userInfoResponse));
                handleCloseInfo();
            });
        }, ({ response }) => {
            setSnackbarState({
                open: true,
                message: response?.data?.msg || 'Oops! Something went wrong',
                severity: 'error'
            });
        }))
    };


    return <Fragment>
        <LogInDialog openDialog={state.loginDialog} handleCloseDialog={handleCloseLogin} onSubmit={handleSubmitLogin} handleClickSignUpOpt={handleClickSignUpOpt} />
        <SignUpDialog openDialog={state.signUpDialog} handleCloseDialog={handleCloseSignUp} onSubmit={handleSubmitSignUp} handleClickAlreadyLogin={handleClickAlreadyLogin} />
        <OTPDialog openDialog={state.otpDialog} handleCloseDialog={handleCloseOtp} onSubmit={handleSubmitOtp} />
        <PersonalInformation openDialog={state.profileDialog} handleCloseDialog={handleCloseInfo} onSubmit={handleSubmitInfo} />

        {snackbarComponent()}
    </Fragment>
}

export default AllDialogs;
