import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getUserInfo,
  updateUserInfo,
  updateProfile,
  getUserProfile,
  dispatchModelsAndFavModelsInStore,
  getFileUrl,
  updateUserImageUrl,
  getFavModels,
  updateLikedModels,
} from "../actions";

function useInitialLoad() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const fetchData = async () => {
    let userInfoResponse;
    let favModels;
    let userProfileResponse;
    let userImageResponse;
    try {
      setIsLoading(true);
      // Call getUserInfo first and wait for it to resolve
      userInfoResponse = await getUserInfo();
      dispatch(updateUserInfo(userInfoResponse));

      favModels = await getFavModels();
      console.log("favModels", favModels)
      dispatch(dispatchModelsAndFavModelsInStore(favModels));

      // Now call getUserProfile and wait for it to resolve
      // userProfileResponse = await getUserProfile();
      // dispatch(updateProfile(userProfileResponse.data));

      // Now call getUserProfile and wait for it to resolve
      userImageResponse = await getFileUrl(userInfoResponse.account_info.user_img);
      if(userImageResponse){
        dispatch(updateUserImageUrl(userImageResponse.data));
      }

      // ... other initial API calls
    } catch (error) {
      // Handle any errors that occurred during the API calls
      console.error("An error occurred during the API calls:", error);
    } finally {
      setIsLoading(false);
    }
  };
  let token = localStorage.getItem("accessToken");

  useEffect(() => {
    if (token) {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, []);

  return { isLoading, setIsLoading };
}

export default useInitialLoad;
