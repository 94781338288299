import INITIAL_STATE from "./initialState";
import {
  LOGOUT,
  UPDATE_PROFILE,
  UPDATE_USER_INFO,
  DIALOG_STATE,
  UPDATE_USER_IMAGE_URL,
  UPDATE_MODELS,
  UPDATE_MODELS_AND_FAV_MODELS,
  ADD_FAV_MODEL,
  REMOVE_FAV_MODEL,
} from "../constants/actionTypes";

const biosReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_PROFILE:
      return { ...state, userProfile: action.payload };
    case UPDATE_USER_INFO:
      return { ...state, user: action.payload };
    case LOGOUT:
      return INITIAL_STATE;
    case DIALOG_STATE:
      return { ...state, ...action.payload };
    case UPDATE_USER_IMAGE_URL:
      return { ...state, user_image_url: action.payload };
    case UPDATE_MODELS:
      return { ...state, uploadedModelsState: action.payload };
    case UPDATE_MODELS_AND_FAV_MODELS:
      console.log("action.payload", action.payload)
      // const modelIds =
      //   action.payload?.data?.model?.map(
      //     (item) => item?.model?.model_id
      //   ) || [];
      // const favModels = action.payload?.data?.model?.filter((m) =>
      //   modelIds.includes(m?.model_id)
      // );
      return {
        ...state,
        // uploadedModelsState: action.payload.allModels?.items || [],
        likedModels: action.payload?.data?.model,
        favModelsState: action.payload?.data?.model || [],
      };
    case ADD_FAV_MODEL:
      const addModel = [...state.favModelsState, action.payload];
      return {
        ...state,
        favModelsState: addModel,
      };
    case REMOVE_FAV_MODEL:
      const removeModel = state.favModelsState.filter(
        (fm) => fm.model_id !== action.payload.model_id
      );
      return {
        ...state,
        favModelsState: removeModel,
      };
    // case LIKED_MODEL:
    //   return { ...state, liked: action.payload };
    default:
      return state;
  }
};

export default biosReducer;
