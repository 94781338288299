import React, { useMemo, useState } from "react";
import Header from "../../components/Header";
import "./Platform.css";
import { Box, Grid, styled, useMediaQuery } from "@mui/material";
import { animatedList, modelTypesList, models, tabs } from "./constants";
import DuplicateCard from "../../components/DuplicateCard";
import classNames from "classnames";
import SearchIcon from "../../assets/svg/search";
import FilterIcon from "../../assets/svg/filter";
import TriggerIcon from "../../assets/svg/trigger";
import FilterTypeOne from "../../assets/svg/filter-type-one";
import FilterTypeTwo from "../../assets/svg/filter-type-two";
import FilterTypeThree from "../../assets/svg/filter-type-three";
import FilterTypeFour from "../../assets/svg/filter-type-four";
import FilterTypeFive from "../../assets/svg/filter-type-five";
import Slider from "@mui/material/Slider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { useTheme } from "@emotion/react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useQuery } from "react-query";
import { getUploadedModelsWithoutToken } from "../../actions";
import Card from "../../components/Card";
import { useLocation } from 'react-router-dom';


const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1b1c30",
    color: "#b5b5db",
    boxShadow: theme.shadows[1],
    border: "1px solid #505083",
    fontSize: 11,
  },
}));

const Platform = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const location = useLocation();

  const { data, refetch } = useQuery(
    "allModelInfo",
    () => getUploadedModelsWithoutToken(),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => { },
    }
  );

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const [sliderValue, setSliderValue] = React.useState([60, 300]);

  const handleChangeSlider = (event, newValue) => {
    setSliderValue(newValue);
  };

  const [modelFormat, setModelFormat] = useState([0]);

  const handleToggleModelFormat = (value) => () => {
    const currentIndex = modelFormat.indexOf(value);
    const newChecked = [...modelFormat];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setModelFormat(newChecked);
  };

  const [modelTypes, setModelTypes] = useState([0]);

  const handleToggleModelType = (value) => () => {
    const currentIndex = modelTypes.indexOf(value);
    const newChecked = [...modelTypes];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setModelTypes(newChecked);
  };

  const [animated, setAnimated] = useState([0]);

  const handleToggleAnimated = (value) => () => {
    const currentIndex = animated.indexOf(value);
    const newChecked = [...animated];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setAnimated(newChecked);
  };


  const isPlatformPage = useMemo(() => {
    return !!location.pathname === '/platform'
  }, [location]);


  return (
    <div className="App">
      <Header />
      <div className="platform-container">
        <div className="tabs-container">
          {tabs.map((tab, index) => {
            return (
              <p
                className={classNames("tab-item-text", {
                  "active-tab": selectedTab.key === tab.key,
                })}
                key={index + tab.key}
                onClick={(e) => handleChange(e, tab)}
              >
                {tab.label}
              </p>
            );
          })}
        </div>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "14px",
            justifyContent: "center",
          }}
        >
          <div className="search-bar">
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearch}
              className="input-custom-search"
            />
            <SearchIcon />
          </div>
          {/* <FilterIcon style={{ marginTop: "15px" }} /> */}
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "16px",
            flexDirection: isSmallScreen ? "column" : "row",
          }}
        >
          {/* <div className="filter-container">
            <div className="filter-title">
              <span>Filter</span>
              <TriggerIcon />
            </div>
            <span className="filter-heading">Model Quality</span>
            <div className="filter-icons-list">
              <LightTooltip
                title="Model based on some image & clearly depicts a structure or process"
                placement="top"
              >
                <span>
                  <FilterTypeOne />
                </span>
              </LightTooltip>
              <LightTooltip
                title="Model based on composited Technical Imaging"
                placement="top"
              >
                <span>
                  <FilterTypeTwo />
                </span>
              </LightTooltip>
              <LightTooltip
                title="Citation used to further edit & model"
                placement="top"
              >
                <span>
                  <FilterTypeThree />
                </span>
              </LightTooltip>
              <LightTooltip
                title="Academic or Scientific Research Partnership"
                placement="top"
              >
                <span>
                  <FilterTypeFour />
                </span>
              </LightTooltip>
              <LightTooltip
                title="Published in Peer-Reviewed Journal"
                placement="top"
              >
                <span>
                  <FilterTypeFive />
                </span>
              </LightTooltip>
            </div>
            <span className="filter-heading">Price ($)</span>
            <Box sx={{ width: "auto" }}>
              <Slider
                size="small"
                value={sliderValue}
                onChange={handleChangeSlider}
                valueLabelDisplay="auto"
                max={400}
                min={0}
              />
            </Box>
            <span className="filter-heading">Common Names</span>
            <div
              className="common-container"
              style={{ minHeight: "101px" }}
            ></div>
            <span className="filter-heading">Model Formats</span>
            <div className="common-container">
              <List>
                {models.map((value, index) => {
                  const labelId = `checkbox-list-Model-Format-label-${index}`;

                  return (
                    <ListItem
                      key={index + "Model-Format"}
                      role={undefined}
                      dense
                      button
                      onClick={handleToggleModelFormat(index)}
                    >
                      <Checkbox
                        edge="start"
                        checked={modelFormat.indexOf(index) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                        size="small"
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 18 },
                          color: "#505083",
                          padding: "0px 5px",
                          "&.Mui-checked": {
                            color: "#505083",
                          },
                        }}
                      />
                      <ListItemText
                        id={labelId}
                        primary={value}
                        className="list-item-custom"
                      />
                    </ListItem>
                  );
                })}
              </List>
            </div>
            <span className="filter-heading">Model License Type </span>
            <div className="common-container">
              <List>
                {modelTypesList.map((value, index) => {
                  const labelId = `checkbox-list-Model-Type-Label-${index}`;

                  return (
                    <ListItem
                      key={index + "Model-Type"}
                      role={undefined}
                      dense
                      button
                      onClick={handleToggleModelType(index)}
                    >
                      <Checkbox
                        edge="start"
                        checked={modelTypes.indexOf(index) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                        size="small"
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 18 },
                          color: "#505083",
                          padding: "0px 5px",
                          "&.Mui-checked": {
                            color: "#505083",
                          },
                        }}
                      />
                      <ListItemText
                        id={labelId}
                        primary={value}
                        className="list-item-custom"
                      />
                    </ListItem>
                  );
                })}
              </List>
            </div>

            <span className="filter-heading">Bio Process</span>
            <div
              className="common-container"
              style={{ minHeight: "101px" }}
            ></div>
            <span className="filter-heading">Animated</span>
            <div className="common-container">
              <List>
                {animatedList.map((value, index) => {
                  const labelId = `checkbox-list-Animated-Type-Label-${index}`;

                  return (
                    <ListItem
                      key={index + "Animated-Type"}
                      role={undefined}
                      dense
                      button
                      onClick={handleToggleAnimated(index)}
                    >
                      <Checkbox
                        edge="start"
                        checked={animated.indexOf(index) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                        size="small"
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 18 },
                          color: "#505083",
                          padding: "0px 5px",
                          "&.Mui-checked": {
                            color: "#505083",
                          },
                        }}
                      />
                      <ListItemText
                        id={labelId}
                        primary={value}
                        className="list-item-custom"
                      />
                    </ListItem>
                  );
                })}
              </List>
            </div>
          </div> */}

          <div className="filter-container-right">
            <Grid container spacing={2} sx={{ justifyContent: "center" }}>
              {data?.data?.map((card, index) => {
                return (
                  <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                    <Card cardInfo={card} isEditable={false} />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default Platform;
