import React, { useEffect, useCallback } from "react";
import "./Card.css";
import { Avatar, Chip, Typography, styled, Button } from "@mui/material";

import ThreeDImage from "../../assets/images/3DViewerRectangle.png";
import SvgOneIcon from "../../assets/svg/svgOne";
import SvgTwoIcon from "../../assets/svg/svgTwo";
import SvgThreeIcon from "../../assets/svg/svgThree";
import SvgFourIcon from "../../assets/svg/svgFour";
import SvgFiveIcon from "../../assets/svg/svgFive";
import CertificationIconOne from "../../assets/svg/certificate_diploma_seal_icon 2";
import CertificationIconTwo from "../../assets/svg/certificate_diploma_seal_icon 3";
import CertificationIconThree from "../../assets/svg/certificate_diploma_seal";
import CertificationIconFour from "../../assets/svg/certificate_diploma_seal_icon 4";
import CertificationIconFive from "../../assets/svg/certificate_diploma_seal_icon 5";
import CertificationIconSix from "../../assets/svg/certificate_diploma_seal_icon 6";
import CertificationIconSeven from "../../assets/svg/certificate_diploma_seal_icon 7";
import ViewDialog from "../ViewDialog";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { getFileUrl } from "../../actions";
import UploadDialog from "../../components/UploadModel";
import { isArray, isString } from "lodash";
import { getUploadedModels } from "../../actions";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";


import AgreementBusinessIcon from "../../assets/svg/agreementBusiness";
import CornerFrameIcon from "../../assets/svg/cornerFrame";
import BookCopybookIcon from "../../assets/svg/bookCopybook";
import FrameIcon from "../../assets/svg/frameIcon";
import ImageAlbumIcon from "../../assets/svg/imageAlbum";
import likeTest from "../../assets/svg/likeTest";

import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';


function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name = "-", width = 60, height = 60, fontSize = 24) {
  const childrenValue = `${name.split(" ")[0][0]}${name?.split(" ")?.[1]?.[0] || ""
    }`;
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: width,
      height: height,
      marginRight: "5px",
      fontSize: `${fontSize}px`,
    },
    children: childrenValue,
  };
}

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1b1c30",
    color: "#b5b5db",
    boxShadow: theme.shadows[1],
    border: "1px solid #505083",
    fontSize: 11,
  },
}));


function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + '...';
}


export const LimitedText = ({ text, maxLength }) => {
  const truncatedText = truncateText(text, maxLength);

  return (
    <div>
      {truncatedText}
    </div>
  );
};

const Card = (props) => {
  const { cardInfo = null, onDelete, isEditable = true } = props;
  const [viewModal, setViewModal] = React.useState(false);
  const [uploadModel, setUploadModel] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState(null);
  const dispatch = useDispatch();
  // const { userProfile } = useSelector((state) => state.bios);
  const state = useSelector((state) => state.bios);
  const userProfile = state.user && state.user?.account_info || {};

  // const getAllModelsData = useQuery(
  //   "getAllModels",
  //   () => getUploadedModels(dispatch),
  //   {
  //     retry: false,
  //     // refetchOnWindowFocus: false,
  //   }
  // );

  const handleOpenViewDialog = useCallback(() => {
    setViewModal(true);
  }, []);

  const handleCloseViewDialog = useCallback(() => {
    setViewModal(false);
  }, []);

  const handleCloseUploadDialog = useCallback(() => {
    setUploadModel(false);
  }, []);

  const handleOpenUploadDialog = useCallback(() => {
    console.log("tracccccccccccccccccccccccck userProfile", userProfile);
    console.log("tracccccccccccccccccccccccck isEditable", isEditable);
    // asdas
    if (userProfile && isEditable) {
      setUploadModel(true);
    }
  }, [userProfile, isEditable]); // dependencies added to ensure proper memoization

  console.log("tracccccccccccccccccccccccck cardInfo cardInfo", cardInfo);

  useEffect(() => {
    if (cardInfo && !imageUrl) {
      if (cardInfo?.uploaded_file_info?.modelInfo?.productPreview.length) {
        getFileUrl(
          cardInfo?.uploaded_file_info.modelInfo?.productPreview[0].fileName
        )
          .then((response) => {
            setImageUrl(response.data);
          })
          .catch((error) => { });
      }
    }
  }, [cardInfo, imageUrl]);

  const tagStyle = {
    height: "23px",
    borderRadius: "4px",
    margin: "5px",
    // position: "absolute",
    "& .MuiChip-label": {
      fontSize: '12px',
      paddingRight: '6px',
      paddingLeft: '6px'
    }
  };

  const viewTag = {
    public: <Chip label="Public" color="primary" sx={tagStyle} />,
    private: (
      <Chip
        label="Private"
        sx={{
          ...tagStyle,
          background: "#494959",
        }}
      />
    ),
    continueUpload: (
      <Chip
        label="Continue Upload"
        sx={{
          ...tagStyle,
          background: "rgba(251, 189, 0, 1)",
        }}
      />
    ),
  };

  const getPermissionTags = () => {
    if (
      cardInfo?.is_approved &&
      cardInfo?.is_published
    ) {
      return viewTag[`public`];
    } else if (cardInfo?.is_published) {
      return viewTag[`private`];
    } else {
      return viewTag[`continueUpload`];
    }
  };

  // console.log("cardInfo cardInfo ", cardInfo)
  return (
    <>
      <div className="propstandard" >
        {isEditable && <div style={{ display: "flex", justifyContent: "space-between" }}>

          {getPermissionTags()}
          <Button variant={"text"} style={{ textTransform: "none", color: "#d2d2ff80" }} onClick={() => {
            onDelete(cardInfo.id);
          }}>
            Delete
          </Button>
        </div>}
        <img onClick={handleOpenViewDialog} alt="Not Found" src={imageUrl ?? ThreeDImage} />
      </div>
      <div className="infoField" onClick={handleOpenUploadDialog}>

        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "32px" }}>

          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <Typography variant="h4" className="rec-title">
              {cardInfo?.name || ""}
            </Typography>
            <span className="cost-title" style={{ display: "flex", alignItems: "center", marginLeft: "16px" }}>
              <ThumbUpOffAltIcon style={{ marginRight: "4px", fontSize: "16px" }} /> {cardInfo?.uploaded_file_info?.modelInfo?.price || "0"}
            </span>
          </div>

          <div className="view-icon-container" style={{ flexDirection: "row" }}>
            {cardInfo?.uploaded_file_info?.field_icon_view?.map((iconValue) => {
              return viewIcons[iconValue];
            })}
          </div>

        </div>

        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginRight: "8px", marginBottom: "4px" }}>

          <div className="avatar-cnt">
            <Avatar
              {...stringAvatar(`${cardInfo?.user?.email || "-"}`, 15, 15, 6)}
            />
            <p className="user_name-rec">{`${cardInfo?.user?.first_name || ""
              }`}
            </p>
          </div>

          <span className="cost-title">
            {cardInfo?.uploaded_file_info?.modelInfo?.price ? `$${cardInfo?.uploaded_file_info?.modelInfo?.price}` : ""}
          </span>

        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p className="rec-description" style={{ marginTop: "2px" }}>
            <LimitedText text={cardInfo?.uploaded_file_info?.modelInfo?.sceneDescription || ""} maxLength={120} />
            {/* {cardInfo?.uploaded_file_info?.modelInfo?.sceneDescription || ""} */}
          </p>

          {
            cardInfo?.uploaded_file_info?.modelInfo?.licenseInfo && cardInfo?.uploaded_file_info?.modelInfo?.licenseInfo.length ? <div className="license-icon-container">
              <span className="license-title">License Types</span>
              <div style={{ paddingTop: "2px" }}>


                {isArray(cardInfo?.uploaded_file_info?.modelInfo?.licenseInfo) &&
                  cardInfo?.uploaded_file_info?.modelInfo?.licenseInfo?.map(
                    (item) => {
                      return licenseIcons[item];
                    }
                  )}
                {isString(cardInfo?.uploaded_file_info?.modelInfo?.licenseInfo) &&
                  licenseIcons[cardInfo?.uploaded_file_info?.modelInfo?.licenseInfo]}
              </div>
            </div> : ""
          }
        </div>

      </div>
      {viewModal && (
        <ViewDialog
          open={viewModal}
          onClose={handleCloseViewDialog}
          cardInfo={cardInfo}
        />
      )}
      {uploadModel &&
        <UploadDialog
          open={uploadModel}
          onClose={handleCloseUploadDialog}
          modelId={cardInfo.id || null}
          modelObjectDataProps={cardInfo?.uploaded_file_info || null}
        />}
    </>
  );
};

const viewIcons = {
  1: (
    <LightTooltip
      title="Model based on some image & clearly depicts a structure or process"
      placement="top"
    >
      <span>
        <SvgOneIcon />
      </span>
    </LightTooltip>
  ),
  2: (
    <LightTooltip
      title="Model based on composited Technical Imaging"
      placement="top"
    >
      <span>
        <SvgTwoIcon />
      </span>
    </LightTooltip>
  ),
  3: (
    <LightTooltip title="Citation used to further edit & model" placement="top">
      <span>
        <SvgThreeIcon />
      </span>
    </LightTooltip>
  ),
  4: (
    <LightTooltip
      title="Academic or Scientific Research Partnership"
      placement="top"
    >
      <span>
        <SvgFourIcon />
      </span>
    </LightTooltip>
  ),
  5: (
    <LightTooltip title="Published in Peer-Reviewed Journal" placement="top">
      <span>
        <SvgFiveIcon />
      </span>
    </LightTooltip>
  ),
};

const licenseIcons = {
  "CC 0": <CertificationIconOne />,
  "CC BY": <CertificationIconTwo />,
  "CC BY-SA": <CertificationIconThree />,
  "CC BY-ND": <CertificationIconFour />,
  "CC BY-NC": <CertificationIconFive />,
  "CC BY-NC-SA": <CertificationIconSix />,
  "CC BY-NC-ND": <CertificationIconSeven />,
};
export default Card;
